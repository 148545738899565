<template>
  <div class="sqs-error sqs-error--403">
    <div class="sqs-error-box">
      <img src="@/assets/developing.gif" alt="" />
      <p>{{ messge }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      messge: "您没有权限访问该资源，请求被拒绝！",
    };
  },
};
</script>
<style lang="scss" scoped>
.sqs-error.sqs-error--403 {
  text-align: center;
  font-size: 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .sqs-error-box {
    width: 100%;
    text-align: center;
    p {
      font-size: 3rem;
      .sqs-error-back {
        cursor: pointer;
        color: #3366ff;
      }
    }
  }
}
</style>
